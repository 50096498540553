import React,{useState,useEffect,useRef}  from "react";
import Layout from "../components/layout";
import FormGenerator from "../components/form/FormGenerator";
import List from "../components/form/List";
import styled from "styled-components";
import Data from "../config/DJs"
import Edit from "../components/form/Edit";
import {BaseSelect,BaseFormField,FormSubmit} from "../styles/Global"
import { GetList } from "../utils/ApiFunctions";
import axios from "axios";
import mainjson from '../config/main';


const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const ListContainer = styled.div`
  padding-bottom:40px;
`;

const ListTitle = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: block;
  margin-bottom: .5rem;
  font-weight: 400;
`;

const ListTitleBold = styled.div`
  line-height: 1.5;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  display: block;
  margin-bottom: .5rem;
  font-weight: bold;

`;

const Pad = styled.div`
  padding-bottom:30px;
`;


const InputContainer = styled.div`
 
`;

const Select = styled(BaseSelect)`

`

const Top = styled.div`
padding:25px;

`

const Container = styled.div`
padding:25px;
background-color:white;
`

const ScrollContainer = styled.div`
position:absolute;
overflow:auto;
height:100%;
width:100%;
`

async function deletePoints(){
    
    var r = confirm("Are you certain you want to delete all points without section?");
    if (r == true) {
        console.log("delete");
        let body = {
            "CMSUser-Token":localStorage.getItem('token'),
            "CMSUser-Agent":navigator.userAgent,
            "action":"deletenonsection"
        }
    
        console.log("hola ",mainjson().action_path);
        await axios({
            method: "post",
            url: mainjson().action_path,
            data: body,
          })
        .then((response) => {
           if (response.data) {
               alert("points deleted")
           }
        })
        .catch((error) => {
            console.log("error "+error);
        });
    }
}


export default function Page() {
    const [options, setOptions] = useState([]);
    const [text, setCurrentText] = useState(null);
    const input = useRef();
    const inputRef = useRef();
    const [value, setValue] = useState(0);
    const [imported, setImported] = useState(false);
    const [importing, setImporting] = useState(false);
    const [importasvalue, setimportasValue] = useState(0);
    const filenameRef = useRef();
    const [active, setActive] = useState(true);
    const [from, setFrom] = useState("none");
    const [kid, setKid] = useState("none");
    const [adult, setAdult] = useState("none");
    const [fromState, setFromState] = useState("none");
    const [to, setTo] = useState("none");
    const [fromCopy, setFromCopy] = useState("none");
    const [copyValue, setCopyValue] = useState("");


    function changedCopy(e){   
      setCopyValue(e.target.value);
    }

    async function setState() {
      var r = confirm("Are you certain want to change states of points in  '"+getOption(fromState) + "'");
      if (r == true) {
          let body = {
              "CMSUser-Token":localStorage.getItem('token'),
              "CMSUser-Agent":navigator.userAgent,
              "fromState":fromState,
              "kid":kid,
              "adult":adult,
              "action":"setstate"
          }
      
            await axios({
               method: "post",
               url: mainjson().action_path,
               data: body,
             })
           .then((response) => {
              if (response.data) {
                  alert("Point states updated. "+ response.data.kids + " kids points active, "+response.data.adult + " adult points active.");
                  getFeed();
              }
           })
           .catch((error) => {
               console.log("error "+error);
          });
      }
    }
  
    function getDateTime() {
      let dateIn = new Date();
      var yyyy = dateIn.getFullYear();
      var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
      var dd = dateIn.getDate();
      var hours = dateIn.getHours();
      var min = dateIn.getMinutes();
      return String(dd + "-"+mm+"-"+yyyy + " "+ hours +":"+min); // Leading zeros for mm and dd
    }


    function checkActive(){
      console.log("from",from,"to",to);
    }

    function changedFrom(e){
      setFrom(e.target.value);
      checkActive();
  }

  function changedFromState(e){
    setFromState(e.target.value);
   // checkActive();
}

  function changedFromCopy(e){
    setFromCopy(e.target.value);
    setCopyValue(e.target.options[e.target.selectedIndex].text + " copy "+getDateTime());



    checkActive();
}


  function changedTo(e){
    setTo(e.target.value);
    checkActive();
}


function changedKid(e){
  setKid(e.target.value);
}


function changedAdult(e){
  setAdult(e.target.value);
}

  async function migrate(){
      var r = confirm("Are you certain want to migrate all the points from '"+getOption(from) + "' to '"+getOption(to) + "' and delete the section '"+getOption(from) + "'");
      if (r == true) {
          let body = {
              "CMSUser-Token":localStorage.getItem('token'),
              "CMSUser-Agent":navigator.userAgent,
              "from":from,
              "to":to,
              "action":"migrate"
          }
      
            await axios({
               method: "post",
               url: mainjson().action_path,
               data: body,
             })
           .then((response) => {
              if (response.data) {
                  alert("migrated")
                  getFeed();
              }
           })
           .catch((error) => {
               console.log("error "+error);
          });
      }
    }

    async function copySection(){
      var r = confirm("Are you certain want to copy all points in '"+getOption(fromCopy) + "' to '"+copyValue +"'?");
      if (r == true) {
          let body = {
              "CMSUser-Token":localStorage.getItem('token'),
              "CMSUser-Agent":navigator.userAgent,
              "from":fromCopy,
              "name":copyValue,
              "action":"duplicate"
          }
      
            await axios({
               method: "post",
               url: mainjson().action_path,
               data: body,
             })
           .then((response) => {
              if (response.data) {
                  alert("Copied!")
                  setCopyValue("");
                  setFromCopy("none");
                  getFeed();
              }
           })
           .catch((error) => {
               console.log("error "+error);
          });
      }
    }


    function getOption(key){
      for (let i =0;i<options.length;i++) {
        let option = options[i];
        if (option.key == key) {
          return option.value;
        }
      }
    }

    async function getFeed(){
      let data = await GetList("sections",0,9999999,"section_name asc","",{},-1);
      let optionarray = [];
      console.log("data = ",data);
      for (let i =0;i<data.records.data.length;i++) {
          let section = data.records.data[i];
          optionarray.push({key:section.section_id,value:section.section_name});
      }
      setOptions(optionarray);
  }

  useEffect(() => {
    getFeed();
}, []);    
    

  return (
    <Layout>
         <ScrollContainer>
        <Header>
            <Title>
                    Actions
            </Title>

        </Header>
        <Top>
        <Container>
       
        <ListContainer>
                <ListTitleBold>Point actions</ListTitleBold>
                <FormSubmit onClick={deletePoints}>Delete points without section</FormSubmit>
            </ListContainer>
            <ListContainer>
                <ListTitleBold>Change points in section</ListTitleBold>
                <ListTitle>Section</ListTitle>
                <Select onChange={changedFromState} value={fromState}>
                    <option value="none">None</option>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
                <ListTitle>Kid</ListTitle>
                <Select onChange={changedKid} value={kid}>
                  <option value="none">Do not change</option>
                  <option value="active">Set active</option>
                  <option value="inactive">Set inactive</option>
                  <option value="auto">Update based on content</option>
                </Select>
                <ListTitle>Adult</ListTitle>
                <Select onChange={changedAdult} value={adult}>
                  <option value="none">Do not change</option>
                  <option value="active">Set active</option>
                  <option value="inactive">Set inactive</option>
                  <option value="auto">Update based on content</option>
                </Select>

                <Pad></Pad>
                {fromState != "none" &&  <FormSubmit onClick={setState}>Change States</FormSubmit>}
               
            </ListContainer>

            <ListContainer>
                <ListTitleBold>Migrate points</ListTitleBold>
                <ListTitle>From</ListTitle>
                <Select onChange={changedFrom} value={from}>
                    <option value="none">None</option>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
                <ListTitle>To</ListTitle>
                <Select onChange={changedTo} value={to}>
                    <option value="none">None</option>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
                <Pad></Pad>
               
                {to != "none" && from != "none" &&  <FormSubmit onClick={migrate}>Migrate</FormSubmit>}
               
            </ListContainer>
            <ListContainer>
                <ListTitleBold>Copy section points to new section</ListTitleBold>
                <ListTitle>From</ListTitle>
                <Select onChange={changedFromCopy} value={fromCopy}>
                    <option value="none">None</option>
                {options.map((val, index) => {
                    return <option value={val.key} key={val.key} ref={input}>{val.value}</option>
                })}
                </Select>
                <ListTitle>To (new name)</ListTitle>
                <BaseFormField type='text' onChange={changedCopy} value={copyValue||""}/>
                <Pad></Pad>
                {fromCopy != "none" &&  copyValue != "" && <FormSubmit onClick={copySection}>Copy</FormSubmit>}
               
            </ListContainer>
            </Container>
            </Top>
            </ScrollContainer>
    </Layout>
  );
}